<template>
     <CForm ref="form" class="needs-validation"   >
        <CRow>
            <CCol tag="label" sm="3" class="col-form-label">
                Newsletter Status
            </CCol>
            <CCol>
                <CSelect
                placeholder="Newsletter Status"
                :options="status"
                :value.sync="data.newsLetterStatus"
                />
            </CCol>
           
        </CRow>
    </CForm>
</template>

<script>
    export default{
        name: "NewsletterForm",
        props:{
            validated: Boolean,
            params: Object,
            actionType: String,
        },
        watch:{
            params: function(val){
                this.data = {...val};
            },
            actionType:  function(val){
                if(val == 'create'){
                    this.data = {...this.params}
                }
            },
        },
        data: function(){
            return{
                data: this.params ? {...this.params} : {},
                status: ['ready', 'finished', 'stop']
            }
        },
    }
</script>